const findImage = (array = [], query = '') => {
  
  const node = array.find(image => {
    return image.node.name === query
  })
  if (node) {
    return node.node.childImageSharp.fluid;
  }
}

export default findImage
