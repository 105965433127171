import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import styles from './work-list.module.scss'
import WorkItem from '../WorkItem'
import findImage from '../../utils/findImage'

const WorkList = props => {
  const { works, className, action } = props
  const wrapperClass = [styles.works, className].join(' ')

  // Get the items
  const worksItems = works.allJavascriptFrontmatter.edges

  const imagesQuery = useStaticQuery(
    graphql`
      query {
        images: allFile(filter: { name: { regex: "^/-demo/" } }) {
          edges {
            node {
              ...WorkImage
            }
          }
        }
      }
    `
  )
  const images = imagesQuery.images.edges

  if (worksItems) {
    return (
      <div className={wrapperClass}>
        {worksItems.map(({ node: work }, index) => {
          var url = work.frontmatter.path
          var name = work.node.name
          return (
            <WorkItem
              key={url}
              name={name}
              url={url}
              fluid={findImage(images, work.frontmatter.image)}
              image={work.frontmatter.image}
              title={work.frontmatter.title}
              active={props.active === index}
              action={action}
            />
          )
        })}
      </div>
    )
  } else {
    return <div className={wrapperClass}>No works found</div>
  }
}

WorkList.propTypes = {
  data: PropTypes.object,
  action: PropTypes.func,
  className: PropTypes.string,
}

export default WorkList
