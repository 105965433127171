import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Image from '../Image'
import Img from 'gatsby-image'
import styles from './work-item.module.scss'

const WorkItem = props => {
  const { name, url, fluid, image, title, className, active } = props
  //console.log(props);
  
  var itemClass = [
    styles.item,
    className,
    name,
    active ? styles.active : null,
  ].join(' ')

  return (
    <div className={itemClass} key={url}>
      <Link to={url} onClick={props.action} activeClassName={styles.active}>
        <div className={styles.image}>
          {fluid ? (
            <Img fluid={fluid} alt={title} />
          ) : (
            <Image src={image} alt={title} />
          )}
        </div>
        <div className={styles.title}>{title}</div>
      </Link>
    </div>
  )
}

WorkItem.propTypes = {
  url: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default WorkItem
