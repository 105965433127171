import React, { useState } from 'react'
import PropTypes from 'prop-types'

import useIntersect from '../../utils/useIntesect'
import styles from './image.module.scss'

const intersectionConfig = {
  rootMargin: '50px 50px',
  threshold: 0.1,
}

const Image = props => {
  const { src, alt, className, width, height } = props
  const [isLoaded, setLoaded] = useState(false)
  const [ref, entry, unobserve] = useIntersect( intersectionConfig )

  const imageClassName = [
    styles.image,
    className,
    isLoaded ? styles.loaded : null,
  ].join(' ')

  if (entry.isIntersecting) {
    entry.target.src = src
    unobserve()
  }

  const handleImageLoaded = event => {
    setLoaded(true)
  }

  return (
    <img
      onLoad={handleImageLoaded}
      className={imageClassName}
      width={width}
      height={height}
      ref={ref}
      alt={alt}
    />
  )
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
}

export default Image
